header {
	#taalkeuze-en-zoek {
		right: 0px;
	}
}

#paginacontainer {
	.contentcontainer {
		margin-top: 30px;
		min-height: 900px;
		padding-right: 0;

		.zijkolom {
			.tekst {
				background-color: transparent;
			}
		}


		.col-small-12:first-child {
			.content {
				background-image: none;
				background-position: left top;
				background-repeat: no-repeat;
				padding-top: 20px;
			}
		}

		.content {
			padding: 0px 15px 20px 15px;
			background-color: transparent;
			//font-size: 1.06em;
			//line-height: 1.355em;

			h1 {
				font-size: 1.59em;
				line-height: 1.38em;
			}
			h2 {
				font-size: 1.18em;
				line-height: 1.35em;
			}
			h3 {
				font-size: 1.07em;
				line-height: 1.35em;
			}
			h4 {
				font-size: 1.0em;
				line-height: 1.4em;
			}

			.introductie {
				color: $blauw;
				font-size: 1.3em;
				line-height: 1.37em;
				font-weight: 300;
			}
			img {
				width: 100%;
				height: auto;
				margin: 15px 0 30px 0;
			}
		}

		.tekstpagina {
			background-color: transparent;
			margin-bottom: 20px;
			padding: 20px;
		}
	}

	#subnavigatie {
		#downloadmenu {
			.button {
				padding: 12px 5px 12px 0px ;
				font-size: 15px;
				&.download-jaarverslag{
					font-size: 15px;
					padding: 12px 12px;
					margin-left: 5px;
				}
				.icon {
					border: 0px solid transparent;
				}
				.tekst {
					font-size: 15px;
					font-weight: 600;
				}
			}
		}
		.row {
			.content {
				background-color: $bglichtblauw;
				padding: 0;
				a#togglehoofdmenu {
					color: $donkerblauw;
					padding-left: 40px;
					display: block;
				}
			}
		}
	}
}

#bladeren {
	a {
		.icon {
			transition: all 0.3s ease;
			text-decoration: none;
			padding-top: 7px;
			text-align: center;
			display: inline-block;
			margin-left: 10px;
			width: 24px;
			height: 36px;
			border-radius: 18px 18px 18px 18px;
			-moz-border-radius: 18px 18px 18px 18px;
			-webkit-border-radius: 18px 18px 18px 18px;
			border: 0px solid transparent;
			font-size: 17px;
		}
	}
}



#navigatie-desktop {
	transition: all 0.3s ease;
	background-color: $donkerblauw;
	z-index: 2000;
	max-width: 400px;
	padding: 10px 40px 20px 40px;

	ul.hoofdmenu {
		padding: 0 0 4px 0;
		display: block;
		li {
			//font-size: 18px;
			//line-height: 1.4em;
			list-style: none;
			padding: 8px 0 8px 0;
			a {
				display: block;
				color: #fff;

				+ ul.submenu {
					display: none;
					padding: 0 0 0 15px;
					li {
						padding: 0;
						a {
							font-size: 14px;
							font-weight: 400;
							padding: 0px;
							color: #fff;

							&.actief {
								font-weight: 700;
							}
						}
					}
				}
			}
			&.borderT2 {
				border-top: 2px solid #fff;
			}
		}

		&> li {
			border-bottom: 1px solid #fff;
			a {
				font-weight: 600;
				color: #fff;

				&.actief {
					color: #fff;
					font-weight: 700;
					+ ul.submenu {
/*						display: block; */
					}
				}
			}
		}
	}
}


@media handheld, only screen and (max-width: 767px) {
	#paginacontainer {
		#navigatie-desktop {
			display: none;
		}
	}
}



@media handheld, only screen and (max-width: 1140px) {
	#navigatie-desktop {
		padding: 0px 20px;
		ul.hoofdmenu {
			li {
				font-size: 14px;
				a + ul.submenu {
					li {
						a {
							font-size: 14px;
						}
					}
				}
			}
		}
	}
	#paginacontainer {
		#subnavigatie {
			.row {
				.content {
					a#togglehoofdmenu {
						display: none;
					}
				}
			}
			#downloadmenu {
				.button {
					&.download-jaarverslag {
						font-size: 14px;
					}
					.tekst {
						font-size: 14px;
					}
				}
			}
		}
	}
}
