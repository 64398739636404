a {
  &.lightbox-link {
    cursor: zoom-in;
  }
}

.half-width {
  max-width: 46%;
  margin: 0 2% 20px;
  float: left;

  & + *:not(.half-width) {
    clear: both;
  }
}

#paginacontainer {

	table.financien {
		margin: 20px 0;
		width: 100%;
    p {
      margin-bottom: 0;
    }
		thead {
			tr {
				text-align: left;
        &:last-child {
          border-bottom: 1px solid $donkerblauw;
        }
				th {
					padding: 3px 8px;
					font-weight: 600;
          color: $donkerblauw;
          &:not(:first-child) {
  					//background-color: $mediumblauw;
          }
					&.highlight {
            background-color: #d8effa;
						color: $donkerblauw;
					}
				}
			}
		}
		tbody {
			tr {
				border-bottom: 1px solid #aaa;
				td {
					padding: 3px 8px;
          &:not(:first-child) {
            //background-color: $mediumblauw;
          }
					&.highlight {
						background-color: #d8effa;
						//color: $blauw;
					}

          &.header,
          &.header ~ * {
						background-color: transparent;

            p {
              font-weight: 600;
              color:$blauw;
              padding-top: 20px;
            }
          }
				}
				&.subtotals {
					font-weight: 600;
				}
			}
		}
		tfoot {
			tr {
				font-weight: 600;
				td {
					padding: 3px 8px;
					background-color: $blauw;
					color: #fff;

					&.highlight {
						background-color: #d8effa;
						color: $blauw;
					}
				}
			}
		}

    .subtotal {

      th,
      td {

        &.normal {
          color: #fff;
          background-color: #8fcef1;
        }
        &.highlight {
          color: #fff;
          background-color: #78c7ef;
        }
        &:first-of-type {
          color: $blauw;
        }
      }
    }

    .total {

      th,
      td {
        font-weight: 600;
        &.normal {
          //color: #fff;
          //background-color: #3cb8ea;
        }
        &.highlight {
          //color: #fff;
          //background-color: #00abe5;
        }
        &:first-of-type {
          //color: $blauw;
        }
      }
    }
	}

	#subnavigatie {
    #downloadmenu .button{
      .tekst {
        display: block;
      }
      .tekst.remove {
        display: none;
      }
      &.voeg-toe-aan-pdf.minus {
    		.icon {
    			background-image: url(../img/voeg-toe-aan-pdf-minus.png);
    		}
    		&:hover {
    			.icon {
    				background-image: url(../img/voeg-toe-aan-pdf_licht-minus.png);
    			}
  		  }
    		.tekst {
          display: none !important;
        }
      .tekst.remove {
        display: block !important;
      }
  	}
    }

  }

  .contentcontainer {
    .content {
      a {
        text-decoration: underline;
      }
      &:after {
        content: "";
        display: block;
        clear: both;
      }
      table {

        img {
          width: auto;
          height: auto;
          margin: 0;
        }
      }
		}


    .chart {
      margin-top:20px;
      margin-bottom:20px;

      > p {
        margin-bottom: 0pt;
      }
    }
  }

}

.link-block {
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  color: #8dc8e8;
  font-style: italic;
  text-decoration: underline;
  margin-bottom: 20px;
}

.explanation-block {
  padding: 10px;
  margin: 10px 0 20px;
  background-color: $bgmediumblauw;
}

/* Table wrapper */
.table-wrapper {position:relative;overflow: hidden;margin:-30px 0 0 -30px;padding:30px 0 30px 30px;}
.table-wrapper table {width:880px;margin-bottom:0;}
.table-overlay {z-index: 10;position:absolute;top:29px;right:-1px;bottom:29px;width:40%;box-shadow: inset -18px 1px 48px -35px rgba(0,0,0,0.4);
background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 90%, rgba(255,255,255,1) 100%); /* FF3.6+ */
background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,0)), color-stop(90%,rgba(255,255,255,1)), color-stop(100%,rgba(255,255,255,1))); /* Chrome,Safari4+ */
background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 90%,rgba(255,255,255,1) 100%); /* Chrome10+,Safari5.1+ */
background: -o-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 90%,rgba(255,255,255,1) 100%); /* Opera 11.10+ */
background: -ms-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 90%,rgba(255,255,255,1) 100%); /* IE10+ */
background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 90%,rgba(255,255,255,1) 100%); /* W3C */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
}
.table-zoom {z-index: 11;position:absolute;right:-1px;width:40px;height:40px;background-color:#fff;background:url(../img/vergrootglas.png) center center no-repeat #fff;background-size:cover;}
.table-close {display:none;position:absolute;top:10px;width:35px;height:30px;background:url(../img/sluiten.png) center center no-repeat #fff;}
.table-wrapper.popup {/*float: right;*/margin:20px 0;padding:50px 20px 20px;background-color: #fff;box-shadow: 0 0 4px 0; }
@media (max-width: 991px) {
  .table-wrapper.popup {overflow:scroll;width: 100%;}
  .table-close {left:20px;}
}
@media (min-width: 992px) {
  .table-wrapper.popup {overflow:visible;width: 920px;z-index: 1}
  .table-close {right:10px;}
}
.popup .table-overlay, .popup .table-zoom {display:none;}
.popup .table-close {display:block;}

@media screen and (max-width: 991px) {
  .div-table-wrapper-scroll {
    overflow-x: hidden;
  }
}

@media screen and (min-width: 992px) {
  .div-table-wrapper-scroll {
    overflow-x: hidden;
  }
}

.award-block {
  p {
    color: $blauw;
  }
}

.home {
  #paginacontainer #contentspacer {
    height: 0;
  }

  #paginacontainer {
    height: calc(100vh - 196px);
    @media handheld, only screen and (max-width: 767px) {
      height: calc(100vh - 116px);
    }
  }
  .groot_beeld {
    position: relative;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-image: url(../img/home.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    #quote {
      position: absolute;
      top: 170px;
      width: 100%;
      color: #fff;
      font-weight: 600;
      .inner {
          margin: 0 auto;
          font-size: 30px;
          line-height: 38px;
          text-align: center;
        }
      .inner h2 {
        color: #fff;
        //font-family: Evenfall, Arial, sans-serif;
        font-size: 71px;
        line-height: 0.9em;
        font-weight: normal;
        text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
        margin: 0 0 10px 0;
        text-shadow: 0 0 15px #000000;
      }
    }
  }
  #hoofdmenu {
    display: none;
    position: absolute;
    right: 0px;
    top: 65px;
    background-color: $donkerblauw;
    z-index: 17000;
    padding: 20px 20px 30px 40px;
    overflow: auto;
    .titel {
        color: #fff;
        font-weight: 700;
        border-bottom: 3px solid #fff;
        padding: 0 0 12px 0;
        margin: 10px 0 20px;
    }
    ul.hoofdmenu {
       display: block;
       padding: 0;
       margin: 0;
       li {
           list-style: none;
           padding: 8px 0 8px 0;
        }
    }
    ul.hoofdmenu > li {
        border-bottom: 1px solid #fff;
        a {
            font-weight: 600;
            color: #fff;
        }
    }
}

header #open-hoofdmenu {
    position: absolute;
    z-index: 16003;
    right: 50px;
    top: 0px;
    height: 80px;
    line-height: 80px;
    padding: 0 10px 0 15px;
    width: 250px;
    //background-color: #009cde;

    a {
      display: block;
      font-size: 16px;
      color: $donkerblauw;
      padding-right: 45px;
      .fal {
          position: absolute;
          right: 55px;
          top: 25px;
          display: inline-block;
          height: 50px;
          margin: 0 0 0 20px;
          font-size: 30px;
          padding-top: 0px;
      }
    }
    a:hover {
        text-decoration: none;
    }
  }
}
