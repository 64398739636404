/*
Theme Name: 	Swell Responsive Volksbank Jarverslag 2016
Description: 	A clean & swell theme
Version: 		1.0
Author: 		Edwin Slothouber
Author URI: 	http://swell.nl hcghgchg
*/

@import url('https://fonts.googleapis.com/css2?family=Viga&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
	font-family:Evenfall;
	src:url(../fonts/Evenfall-Oblique.eot);
	src:url(../fonts/Evenfall-Oblique.eot) format('embedded-opentype'),
		url(../fonts/Evenfall-Oblique.woff) format('woff'),
		url(../fonts/Evenfall-Oblique.ttf) format('truetype');
	font-style:normal;
	font-weight:400;
}

$viga: "Viga";
$roboto: "Roboto";
$OpenSans: "Open Sans", Arial, sans-serif;
$Evenfall: Evenfall,Arial,sans-serif;

$donkerblauw: #10069F;
$hengelo-blauw-3: #B9975B;

$lichtblauw: #e4f6fc;
$blauw: #10069F;
$bglichtblauw: #f3fafd;
$bgmediumblauw: #e4f6fc;
$donkergrijs: #425563;
$mediumblauw:  #e4f6fc;

.blauw { color: $blauw; }
.bgblauw { background-color: $blauw; color: #fff; }
.bgdonkerblauw { background-color: $donkerblauw; color: #fff; }
.bglichtblauw { background-color: $lichtblauw; color: $donkerblauw; }


html, body {
	height: 100%;
	margin: 0px;
	padding :0;
}
body {
	font-family: $roboto, sans-serif;
	font-weight: 400;
	font-size: 1em;
	line-height: 1.4em;
	color: $donkergrijs;
}
div, p {
	position: relative;
	margin: 0;
}
p {
	margin-bottom: 20px;
}
img {
	max-width: 100%;
	height: auto;
	border: 0px solid #000;
}

iframe {
	max-width: 100%;
	height: auto;
}
a {
	color: $donkergrijs;
	text-decoration: none;
	transition: all 0.3s ease;
}
a:hover {
	text-decoration: underline;
}

h1, h2, h3, h4, h5, h6 {
	font-family: $viga, Arial, sans-serif;
	font-weight: 600;
	line-height: 1.2em;
	color: $donkerblauw;
	margin: 0;
}
h1 {
	font-size: 1.8em;
}
h2 {
	font-size: 22px;
}
h3 {
	font-size: 16px;
}
h4 {
	font-size: 14px;
	color: $donkergrijs;
	text-transform: uppercase;
}
h5 {
	font-size: 14px;
	color: $donkergrijs;
}
h6 {
	font-size: 14px;
	color: $donkergrijs;
}
hr {
	margin: 30px 0;
	border: 0px solid #fff;
	border-bottom: 1px solid #ccc;
}
ul {
	list-style-position:outside;
}
label {
	display: block;
}
input {
	font-family: $roboto, sans-serif;
	font-size: 1em;
	line-height: 1.4em;
	color: $donkergrijs;
	border: 1px solid $blauw;
	margin: 0 0 5px 0;
	padding: 2px 5px;
}
input[type="button"] {
	transition: all 0.3s ease;
}
input[type="submit"] {
	transition: all 0.3s ease;
	border: 1px solid $donkerblauw;
	background-color: #fff;
	color: $donkerblauw;
}
b, strong {
	font-family: $roboto, serif;
	font-weight: 700;
}

.textalignright {
	text-align: right;
}



.titleLabel {
	font-size: 10px;
	color: $blauw;
	border: 1px solid $blauw;
	margin: -5px 5px 0 5px;
	padding: 2px;
	vertical-align: middle;
	font-weight: 300;
}



/* =========================================================================  social icons ======== */
a {
	color: $donkerblauw;
	.icon {
		transition: all 0.3s ease;
		text-decoration: none;
		padding-top: 5px;
		text-align: center;
		display: inline-block;
		margin-left: 10px;
		width: 36px;
		height: 36px;
		border-radius: 18px 18px 18px 18px;
		-moz-border-radius: 18px 18px 18px 18px;
		-webkit-border-radius: 18px 18px 18px 18px;
		border: 2px solid $donkerblauw;
		font-size: 18px;
		&:hover {
			color: $blauw;
			border-color: $blauw;
		}
	}
}



#wrapper {
	&.in {
		position: relative;
		max-height: 100%;
		overflow: hidden;
		opacity: 0.2;
	}
}


/* =========================================================================  header ======== */
.openzoek {
	width: 40px;
	height: 100%;
	background-image: url(../img/vergrootglas.png);

  @media handheld, only screen and (max-width: 767px) {
    background-image: url(../img/vergrootglas-wit.png);
  }

	background-position: center center;
	background-size: 100% auto;
	background-repeat: no-repeat;
	text-decoration: none;
	&:hover {
		text-decoration: none;
	}
}

header {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	z-index: 10000;
	border-bottom: 1px solid #d5d9dd;

	&.zindex {
		z-index: 40000;
	}
	#logo {
		position: absolute;
		left: 10px;
		top: 15px;
		height: 60px;
		width: 135px;
		z-index: 10001;
		background-image: url(../img/logo-hengelo.svg);
		background-size: auto 100%;
		background-position: left center;
		background-repeat: no-repeat;
		transition: all 0.3s ease;
		&.in {
			height: 50px;
		}
		a {
			display: block;
			width: 100%;
			height: 100%;
		}

	}
	#jaarverslag {
		height: 80px;
		line-height: 80px;
		color: $donkerblauw;
		//text-transform: uppercase;
		padding: 0 0 0 150px;
		font-size: 18px;
		font-weight: 600;
		background-color: #fff;
		z-index: 9999;
		transition: all 0.3s ease;
		&.in {
			padding: 0 0 0 135px;
		}
	}
	#hamburger-zoeken {
		display: none;
		position: absolute;
		z-index: 10000;
		right: 0px;
		top: 0px;
		width: 100%;
		height: 50px;
		line-height: 50px;
		text-align: right;
		background-color: $donkerblauw;
		.openzoek {
			display: inline-block;
			position: absolute;
			right: 60px;
			width: 30px;
		}
		#hamburger {
			display: inline-block;
			position: absolute;
			background: url(../img/hamburger0.png) center center no-repeat;
			top: 5px;
			right: 10px;
			width: 40px;
			height: 40px;
			transition: all 0.3s ease;
			&.toggled {
				background: url(../img/hamburger1.png) right top no-repeat;
				-ms-transform: rotate(45deg); /* IE 9 */
				-webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
				transform: rotate(45deg);
			}
		}
	}
	#taalkeuze-en-zoek {
		position: absolute;
		z-index: 10003;
		right: 150px;
		top: 15px;
		width: 85px;
		height: 50px;
		line-height: 50px;
		padding: 0 0 0 35px;
		//background: $donkerblauw url(../img/bg-taalkeuze-en-zoek-vergrootglas.png) left top no-repeat;
		color: #fff;

		a {
			display: inline-block;
			height: 100%;
			color: #fff;

			&.actief {
				font-weight: 700;
			}
		}
		.openzoek {
			margin-left: 10px;
			width: 30px;
		}
	}
	#download-jaarverslag {
		position: absolute;
		z-index: 10003;
		right: 0px;
		top: 0px;
		width: 170px;
		height: 50px;
		line-height: 50px;
		padding: 0 0 0 20px;
		background-color: $blauw;
		a {
			font-size: 13px;
			color: #fff;
		}
	}

	#zoekformulier {
		display: none;
		position: absolute;
		z-index: 10004;
		right: 0px;
		top: 65px;
		background-color: #fff;
		height: 40px;
		width: 250px;
		-webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.4);
		-moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.4);
		box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.4);

		form {
			input {
				width: 200px;
				height:37px;
				line-height: 40px;
				padding: 0 10px;
				margin: 0;
				float: left;
				border: 0px solid #fff;
			}
			input[type="submit"] {
				background-position: center center;
				background-image: url(../img/vergrootglas.png);
				background-size: 25px 25px;
				background-repeat: no-repeat;
				height: 40px;
				width: 40px;
			}
		}
	}
}


/* =========================================================================  desktop-menu ======== */
#navigatie-desktop {
	transition: all 0.3s ease;
	background-color: #fff;
	z-index: 2000;
	max-width: 250px;

	ul.hoofdmenu {
		padding: 0 0 4px 0;
		display: block;
		li {
			font-size: 14px;
			list-style: none;
			padding: 8px 0 8px 0;
			a {
				display: block;
				color: #8dc8e8;

				+ ul.submenu {
					display: none;
					padding: 0 0 0 15px;
					li {
						padding: 0;
						a {
							font-size: 12px;
							font-weight: 400;
							padding: 0px;
							color: #009cde;

							&.actief {
								font-weight: 700;
							}
						}
					}
				}
			}
			&.borderT2 {
				border-top: 2px solid #8dc8e8;
			}
		}

		&> li {
			border-bottom: 1px solid #8dc8e8;
			a {
				font-weight: 600;
				color: #8dc8e8;

				&.actief {
					color: #009cde;
					font-weight: 700;
					+ ul.submenu {
/*						display: block; */
					}
				}
			}
		}
	}

	#stakeholders {
		display: none;
		margin-top: 100px;
		h5 {
			margin-bottom: 10px;
		}
		img {

		}
		a.caption {
			display: block;
			background-color: $blauw;
			font-size: 11px;
			color: #fff;
			min-height: 28px;
			padding: 3px 5px;
			background-image: url(../img/bg-stakeholders-meer.png);
			background-position: right top;
			background-repeat: no-repeat;
			font-style: italic;
			.naam {
			}
			.functie {
				color: $lichtblauw;
			}
			.meer {
				float: right;
				width: 60px;
				text-align: right;
				margin-right: 8px;
			}
		}
	}
}
.hoofdstuk {
	#navigatie-desktop {
		#stakeholders {
			display: block;
		}
	}
}


/* =========================================================================  mobiel-menu ======== */
#navigatie-mobiel {
	display: none;
	position: fixed;
	right: 0px;
	top: 50px;
	width: 100%;
	height: 100%;
	padding-bottom: 100px;
	overflow: auto;
	background-color: #fff;
	z-index: 39999;
	padding-left: 20px;
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0); /* disable highlight on mobile chrome */

	&.in {
		top: 100px;
	}

	ul.mobielmenu {
		padding: 0 0 4px 0;
		display: block;
		li {
			list-style: none;
			padding: 8px 0 8px 0;
			a {
				display: block;
				color: $donkerblauw;
			}
			ul.submenu {
				display: none;
				padding: 0 0 0 15px;
				li {
					padding: 0;
					a {
						font-size: 12px;
						font-weight: 400;
						padding: 0px;
						color: $donkerblauw;

						&.actief {
							font-weight: 700;
						}
					}
				}
			}
			&.borderT2 {
				border-top: 2px solid $donkerblauw;
			}
		}

		&> li {
			border-bottom: 1px solid $donkerblauw;
			a {
				font-weight: 600;
				color: $donkerblauw;

				&.actief {
					color: $donkerblauw;
					font-weight: 700;
				}
			}
		}
	}
}


/* =========================================================================  spacer for fixed header ======== */
#contentspacer {
	height: 80px;
	transition: all 0.3s ease;
	&.in {
		height: 130px;
	}
}


/* =========================================================================  content container ======== */
#paginacontainer {

	table.financien {
		margin: 20px 0;
		width: 100%;
		thead {
			tr {
				text-align: left;
				th {
					padding: 3px 8px;
					font-weight: 600;
					&.present {
						background-color: $lichtblauw;
						color: $blauw;
					}
					&.past {
						background-color: $blauw;
						color: #fff;
					}
				}
			}
		}
		tbody {
			tr {
				border-bottom: 1px solid #aaa;
				td {
					padding: 3px 8px;
					&.present {
						background-color: $lichtblauw;
						color: $blauw;
					}
					&.past {
						background-color: $blauw;
						color: #fff;
					}
				}
				&.subtotals {
					font-weight: 600;
				}
			}
		}
		tfoot {
			tr {
				font-weight: 600;
				td {
					padding: 3px 8px;
					&.present {
						background-color: $lichtblauw;
						color: $blauw;
					}
					&.past {
						background-color: $blauw;
						color: #fff;
					}
				}
			}
		}
	}




	#illustratie {
		width: 100%;
		height: 50px;
		background-color: $donkerblauw;
		//background-image: url(../img/header-illustratie.jpg);
		background-position: center center;
		background-size: cover;
		background-repeat: no-repeat;
		transition: all 0.3s ease;
		&.in {
			height: 0;
		}

		.row {
			height: 100%;


			.col-small-12 {
				height: 100%;

				h2 {
					font-family: $Evenfall, serif;
					font-weight: 400;
					color: $donkerblauw;
					font-size: 60px;
					height: 100%;
					line-height: 50px;
					padding: 70px 0 0 0px;
					background-position: 0 40px;
					background-size: auto 110px;
					background-repeat: no-repeat;
					vertical-align: bottom;

					span {
						display: block;
						text-align: center;
						width: 100%;
						position: absolute;
						bottom: 20px;
					}



					&.hoofdstuk_nr {
						padding: 70px 0 0 140px;
						@for $i from 1 through 15 {
							&.hoofdstuk-#{$i} {
								background-image: url(../img/hoofdstuk-#{$i}.png);
							}
						}
						span {
							display: block;
							bottom: 20px;
							left: 140px;
							text-align: left;
							width: auto;
						}

					}
					&.textcenter {
						text-align: center;
					}
				}
			}
		}
	}
	#subnavigatie {
		position: absolute;
		z-index: 3000;
		width: 100%;
		min-height: 50px;
		background-color: #fff;
		transition: all 0.3s ease;
		border-bottom: 1px solid #d5d9dd;

		&.in {
			position: fixed;
			top: 80px;
		}

		a#togglehoofdmenu {
			display: none;
			max-width: 1440px;
			margin: 0;
			color: $donkergrijs;
			line-height: 50px;
			font-weight: 700;
		}

		#menubutton {
			display: none;
		}
		#downloadmenu {
			position: absolute;
			right: 0;
			top: 0px;
			height: 100%;

			.button {
				display: inline-block;
				padding: 12px 10px;
				height: 100%;
				font-size: 12px;
				&:hover {
					.icon {
						color: $hengelo-blauw-3;
						border-color: $hengelo-blauw-3;
					}
					.tekst {
						color: $hengelo-blauw-3;
					}
				}
				&.download-pagina {
					.icon {
						background-image: url(../img/download-pagina.png);
						&:hover {
							background-image: url(../img/download-pagina_licht.png);
						}
					}
					&:hover {
						.icon {
							background-image: url(../img/download-pagina_licht.png);
						}
					}
				}
				&.voeg-toe-aan-pdf {
					.icon {
						background-image: url(../img/voeg-toe-aan-pdf.png);
					}
					&:hover {
						.icon {
							background-image: url(../img/voeg-toe-aan-pdf_licht.png);
						}
					}
				}
				&.selectie-maken {
					.icon {
						background-image: url(../img/selectie-maken.png);
					}
					&:hover {
						.icon {
							background-image: url(../img/selectie-maken_licht.png);
						}
					}
				}
				.icon {
					float: left;
					display: inline-block;
					margin-right: 5px;
					padding-top: 0px;
					line-height: 20px;
					width: 26px;
					height: 26px;
					border-width: 1px;
					border-radius: 13px 13px 13px 13px;
					-moz-border-radius: 13px 13px 13px 13px;
					-webkit-border-radius: 13px 13px 13px 13px;
					background-repeat: no-repeat;
					background-position: center center;
					background-size: 20px 20px;
				}
				.tekst {
					float: left;
					display: inline-block;
					height: 26px;
					padding-top: 3px;
				}
				&.download-jaarverslag {
					float: right;
					background-color: $donkerblauw;
					color: #fff;
					font-size: 15px;
					&:hover {
						background-color: $hengelo-blauw-3;
						text-decoration: none;
					}
				}
			}
		}
	}

	#contentspacer {
		height: 50px;
	}

	#breadcrumbs {
		position: relative;
		width: 100%;
		min-height: 50px;
		display: none;
		padding: 10px 0;
		a {
			font-size: 11px;
			padding: 0 3px 0 0;
			&:after {
				content: ">";
				padding-left: 7px;
			}
			&:last-child {
				&:after {
					content: "";
				}
			}
		}
	}

	.contentcontainer {
		margin-top: 30px;
		min-height: 900px;
		padding-right: 0;

		.col-small-12:first-child {
			.content {
				background-image: url(../img/contenthoekje.png);
				background-position: left top;
				background-repeat: no-repeat;
				padding-top: 20px;
			}
		}

		.content {
			padding: 0px 15px 20px 15px ;
			background-color: $bglichtblauw;
			font-size: 14px;
			.introductie {
				color: $blauw;
				font-size: 1.4666em;
				line-height: 1.4em;
				font-weight: 300;
			}
			img {
				width: 100%;
				height: auto;
				margin: 30px 0;
			}
		}

		.tekstpagina {
			font-size: 14px;
			background-color: $bglichtblauw;
			margin-bottom: 20px;
			padding: 20px;
		}

		.stakeholder {
			background-color: $bglichtblauw;
			margin-bottom: 20px;
			a {
				text-decoration: none;
				img {
					width: 100%;
					height: auto;
				}

				&:hover {
					text-decoration: none;
					.caption {
						text-decoration: none;
						background-color: $blauw;
						color: #fff;
					}
				}
				blockquote {
					display: table-cell;
					vertical-align: middle;
					height: 90px;
					overflow: hidden;
					margin: 0;
					padding: 10px;
					font-style: italic;
					font-weight: 400;
					color: $blauw;
					text-align: center;
				}
				hr {
					margin: 0 10px;
					height: 1px solid #fff;
					border: 0px solid transparant;
					border-bottom: 1px solid #fff;
				}
				.caption {
					display: block;
					font-size: 13px;
					font-weight: 400;
					padding: 5px 10px;
					color: $blauw;
					text-decoration: none;
					transition: all 0.3s ease;
					&:hover {
					}
					.functie {
						font-weight: 300;
					}
					.meer {
						float: right;
					}
				}
			}

			&.groot {
				img {
					width: 100%;
					height: auto;
				}

				.caption {
					background-color: $blauw;
					color: #fff;
					font-style: italic;
					height: 39px;
					padding: 0 0 0 20px;
					.naam {
						line-height: 39px;
					}
					.ikoon {
						width: 70px;
						height: 39px;
						float: right;
						background-color: #fff;
						background-image: url(../img/bg-stakeholder-ikoon.png);
						background-position: left top;
						background-repeat: no-repeat;
						background-size: auto 100%;
						text-align: right;
						padding-right: 10px;
						img {
							height: 100%;
							width: auto;
						}
					}
				}
				blockquote {
					font-family: $Evenfall;
					font-size: 24px;
					line-height: 1.2em;
					padding: 30px 40px;
				}
				.tekst {
					padding: 20px 40px;
					font-size: 14px;
				}
			}
		}



		.zoekresultaten {
			font-size: 14px;
			h1 {
				margin-bottom: 10px;
			}
			h2 {
				margin-bottom: 10px;
				font-size: 16px;
			}
			h3 {
				margin-bottom: 10px;
				font-size: 14px;
			}
			ul {
				margin: 40px 0;
				li {
					a {
						text-decoration: underline;
						&:hover {
							color: $blauw;
						}
					}
				}
			}
			form {
				margin: 40px 0;
				input[type="text"] {
					display: block;
					float: left;
					width: 200px;
					height: 30px;
					margin-right: 20px;
				}
				input[type="submit"] {
					display: inline-block;
					height: 30px;
					padding: 2px 10px;
					&:hover {
						background-color: $donkerblauw;
            color: #fff;
					}
				}
			}
		}



		#socials {
			padding: 30px 0 15px 0;
			text-align: center;
		}

		#bladeren {
			padding-top: 15px;
			padding-bottom: 30px;
			a {
				display: inline-block;
				width: 50%;
				border-color: $donkerblauw;
				text-align: left;
				font-size:12px;
				color: $donkerblauw;

				span {
					display: inline-block;
					height: 36px;
					margin: 0 0 0 5px;
				}
				&.vorige {
					float: left;
					padding-top: 1px;
					padding-left: 3px;
					.icon {
						float: left;
						.fa {
							margin-left: -3px;
						}
					}
					.tekst {
						line-height: 36px;
					}
					&:hover {
						color: $blauw;
						.icon {
							color: $blauw;
							border-color: $blauw;
						}
					}
				}
				&.volgende {
					float: left;
					text-align: right;
					padding-top: 1px;
					padding-left: 15px;
					.icon {
						float: right;
						.fa {
							margin-left: 3px;
						}
					}
					.tekst {
						line-height: 36px;
					}
					&:hover {
						color: $blauw;
						.icon {
							color: $blauw;
							border-color: $blauw;
						}
					}
				}
			}

		}

	}


	.zijkolom {
		.spreuk {
			font-family: $Evenfall;
			font-size: 28px !important;
			line-height: 1.15em;
			color: $blauw;
		}
		.tekst {
			h4 {
				text-transform: none;
				color: #8dc8e8;
			}
			.klein {
				color: #8dc8e8;
				font-size: 13px;
				line-height: 1.4em;
			}
			.cijfers {
				color: #8dc8e8;
				font-size: 13px;
				line-height: 1.2em;
				font-weight: 600;
				.titel {
					color: #8dc8e8;
					font-size: 14px;
					line-height: 1.4em;
					text-transform: uppercase;
				}
				.nummer {
					display: block;
					font-size: 30px;
					line-height: 1.1em;
					font-weight: 700;
					color: #8dc8e8;
					margin: 0 0 0 0;
				}
			}
		}

	}

}


/* =========================================================================  footer ======== */
#footer {
	background-color: $donkerblauw;
	color: #fff;

	.socials {
		padding: 20px 15px 10px;
		text-align: center;

		a {
			display: inline-block;
      color: #fff;
      font-size: 25px;
      font-weight: 600;

			.icon {
				border-color: #fff;
				color: #fff;
				&:hover {
					color: $hengelo-blauw-3;
					border-color: $hengelo-blauw-3;
				}
			}
		}
	}

	#footernavigatie {
		background-color: bgmediumblauw;
		min-height: 50px;
		padding: 10px 20px;
		text-align: center;
		color: #fff;


		a {
			transition: all 0.3s ease;
			display: inline-block;
			margin: 0 10px;
			height: 100%;
      font-size: 14px;
			font-weight: normal;
			//line-height: 30px;
			//text-transform: uppercase;
			color: #fff;
			&:hover {
				//text-decoration: none;
				//color: $donkergrijs;
			}
		}
	}
}



/* ========================================================================= download-selectie ======== */
#download-selectie-achtergrond {
	position: fixed;
	display: none;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.8);
	z-index: 60000;

	&.in {
		display: block;
	}
}

#download-selectie {
	position: fixed;
	z-index: 60001;
	top: 0px;
	right: -100%;
	width: 768px;
	height: 100%;
	background-color: #fff;
	padding: 20px;
	-webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.4);
	-moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.4);
	box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.4);
    color: #939c9e;
    min-height: 100%;
    overflow: auto;
	transition: all 0.3s ease;

	&.in {
		right: 0px;
	}

	.sluiten {
		position: absolute;
		display: block;
		width: 34px;
		height: 34px;
		right: 20px;
		top: 10px;
		background-image: url(../img/sluiten_licht.png);
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 20px 20px;
		background-position: center center;
		border: 1px solid $blauw;
		border-radius: 17px 17px 17px 17px;
		-moz-border-radius: 17px 17px 17px 17px;
		-webkit-border-radius: 17px 17px 17px 17px;
		&:hover {
			background-image: url(../img/sluiten.png);
			border: 1px solid $donkerblauw;
		}

	}


	#download-center {
		margin-top: 40px;
		max-width: 900px;

		form {

			ul {
				padding-bottom: 10px;
				padding-left: 0px;

				&.first-level > li {
					padding-top: 10px;
					border-top: 1px solid $donkerblauw;
				}

				li {
					list-style: none;
					font-weight: 600;

					input[type="checkbox"] + label {
						display: block;
						padding-bottom: 5px;
						color: $donkerblauw;
						margin: 0;
						cursor: pointer;

						&:before {
							float: left;
							font-family: "FontAwesome";
							font-size: 10px;
							line-height: 12px;
							width: 18px;
							height: 18px;
							margin: 4px 15px 0 0;
							content: "";
							text-align: center;
							color: $donkerblauw;
							border: 1px solid $donkerblauw;
							border-radius: 2px;
						}
					}
					input[type="checkbox"]:checked + label {
						&:before {
							content: "\f00c";
						}
					}
					input[type="checkbox"] {
						display: none;
					}
					&> ul {
						padding-left: 36px;
						li {
							font-weight: 400;
							font-size: 14px;
							input[type="checkbox"] + label {
								&:before {
									width: 15px;
									height: 15px;
								}
							}
						}
					}
				}
			}
		}

		h2 {
			margin: 10px 0;
			color: $blauw;
		}

		.report-overview {
			max-width: 250px;
			background-color: $bglichtblauw;
			padding: 20px;
			&> div {
				padding-bottom: 10px;
			}
			h3 {
				color: $blauw;
				margin-bottom: 10px;
			}
			.winkelwagen {
				display: block;
				height: 24px;
				color: $blauw;
				font-size: 13px;
				margin-bottom: 5px;
				background-image: url(../img/winkelwagentje.png);
				background-position: left bottom;
				background-size: auto 28px ;
				background-repeat: no-repeat;
				padding-left: 40px;
			}
			hr {
				height: 1px;
				overflow: hidden;
				border: 0px solid #fff;
				margin: 5px 0;
				padding: 0;
				border-top: 1px solid $donkerblauw;
			}
			input[type="button"] {
				display: inline-block;
				width: 100%;
				padding: 10px;
				margin: 8px 0;
				&:hover {
					background-color: $donkerblauw;
				}
				&.bgdonkerblauw:hover {
					background-color: $blauw;
				}
			}
			a {
				display: block;
				padding: 5px 0;
				font-weight: 400;
			}

		}
	}
}




/* ========================================================================= media query > 640px ======== */
@media handheld, only screen and (max-width: 640px) {
	#paginacontainer {
		#illustratie {
			.row {
				.col-small-12 {
					h2 {
						&.hoofdstuk_nr {
							span {
								font-size: 30px;
							}
						}
					}
				}
			}
		}
		.contentcontainer {
			.stakeholder.groot {
				.tekst {
					padding: 20px;
				}
			}
		}
	}
}


/* ========================================================================= media query 768px ======== */
@media handheld, only screen and (max-width: 767px) {

	header {
		border-bottom: 0px solid #fff;

		#logo {
			width: 60%;
		}
		#jaarverslag {
			display: none;
		}
		#hamburger-zoeken {
			display: block;
		}
		#logo {
      background-image: url(../img/logo-hengelo-white.svg);
			//height: 50px;
      height: 33px;
		}
		#taalkeuze-en-zoek {
			display: none;
		}
	}


	#navigatie-mobiel {
		ul.mobielmenu {
			li {
				a {
					padding: 10px;
					line-height: 1em;
				}
				ul.submenu {
					li {
						a {
							padding: 10px;
							line-height: 1em;
 						}
					}
				}
			}
		}
	}

	#contentspacer {
		display: none;
	}

	#footer {
		.socials {
			.col-small-12 {
				padding-right: 0;
			}
			a.volksbank {
				display: block;
				margin: 0 auto 30px;
			}
		}
		#footernavigatie {
			a {
				display: block;
			}
		}
	}

	#paginacontainer {

		#subnavigatie {
			#menubutton {
				position: absolute;
				right: 0px;
				top: 0px;
				padding: 13px 30px ;
				display: inline-block;
				color: $blauw;
				font-weight: 600;
				width: 250px;
				text-align: right;
				height: 100%;
				z-index: 40001;
				background-color: #fff;
			}
			#downloadmenu {
				z-index: 40000;
				display: none;
				position: absolute;
				right: 0px;
				top: 51px;
				height: auto;
				padding-top: 5px;
				background-color: #fff;
				-webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.4);
				-moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.4);
				box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.4);
				.button {
					display: block;
					padding: 5px 20px;
					height: 40px;
					&.download-jaarverslag {
						float: none;
					}
					.icon {
						margin-left: 0px;
					}
				}
			}
		}


		#illustratie {
			.row {
				.col-small-12 {
					h2 {
						font-size: 40px;
						line-height: 1em;
						background-size: 50px auto;
						background-position: left 88px;
						padding-left: 50px;

						&.hoofdstuk_nr {
							padding-left: 100px;

							span {
								left: 70px;
							}
						}
						span {
							left: 0px;
							font-size: 40px;
							line-height: 1em;
						}
					}
				}
			}
		}

		#subnavigatie {
			display: none;
		}

		#breadcrumbs {
			display: block;
		}


		#navigatie-desktop {
			position: absolute;
			left: -20px;
			width: 300px;
			-webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.4);
			-moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.4);
			box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.4);

			ul.hoofdmenu {
				display: none;
				padding: 20px 0 30px 0;
				margin: 0 30px 0 10px;
			}

			#stakeholders {
				display: none;
			}

		}

		.contentcontainer {
			margin-top: 0px;
			padding-top: 0px;

			.zijkolom {
				.spreuk {
					text-align: center;
					padding: 30px 15px;
					background-color: $bglichtblauw;
				}
				.tekst {
					text-align: center;
					padding: 30px 15px;
					background-color: $bglichtblauw;
				}
			}
			.stakeholder {
				margin-top: 20px;
			}
			.tekstpagina {
				margin-top: 20px;
			}
		}
	}

	#download-selectie {
		width: 100%;
	}

}

/* ========================================================================= media query 890px ======== */
@media handheld, only screen and (max-width: 890px) {
	#paginacontainer {
	}
}

/* ========================================================================= media query 1024px ======== */
@media handheld, only screen and (max-width: 1024px) {

	#paginacontainer {

		#subnavigatie {
			a#togglehoofdmenu {
				padding-left: 0px
			}
		}
		.contentcontainer {
			padding-left: 0;

			.zijkolom {
				.spreuk {
					text-align: center;
					padding: 15px;
					background-color: $bglichtblauw;
				}
				.tekst {
					padding: 15px;
					background-color: $bglichtblauw;
				}
			}

		}
	}

}



/* ========================================================================= media query > 1025px ======== */
@media handheld, only screen and (min-width: 1025px) {

	#navigatie-desktop {
		ul.hoofdmenu {
			display: block !important
		}
	}

}




@import "2018.scss";

@import "tangelo-custom.scss";
